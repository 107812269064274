import { render, staticRenderFns } from "./apply-frimwares-dialog.vue?vue&type=template&id=52d4fca6&scoped=true"
import script from "./apply-frimwares-dialog.vue?vue&type=script&lang=js"
export * from "./apply-frimwares-dialog.vue?vue&type=script&lang=js"
import style0 from "./apply-frimwares-dialog.vue?vue&type=style&index=0&id=52d4fca6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d4fca6",
  null
  
)

export default component.exports